import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from "../layout/Header";
import background1 from "../assets/bg/services1.jpg";

export class Project_style1 extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div
          className="ttm-page-title-row"
          style={{ backgroundImage: `url(${background1})` }}
        >
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Projects</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" to={process.env.PUBLIC_URL + "/"}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Home
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Projects</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="ttm-row tab-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-12">
                <div className="ttm-tabs text-center ttm-tab-style-classic style2">
                  <Tabs>
                    <TabList className="text-uppercase mb-4">
                      <Tab>
                        <span>all</span>
                      </Tab>

                      {/* <Tab>
                        <span>Innovation</span>
                      </Tab> */}
                    </TabList>
                    <div className="content-tab">
                      <TabPanel>
                        <div className="row multi-columns-row ttm-boxes-spacing-5px">
                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/travelagency.jpeg")}
                                  width={"120px"}
                                  height={"120px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content ">
                                <span class="card-title">Nano Travel </span>
                                <br />
                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://travel.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/schoolnew.jpeg")}
                                  width={"155px"}
                                  height={"155px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content">
                                <span class="card-title">Nano School </span>
                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://school.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/pharmacy.jpg")}
                                  width={"150px"}
                                  height={"150px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content">
                                <span class="card-title">Nano Pharmacy </span>
                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://pharmacy.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/newdental.jpeg")}
                                  width={"100px"}
                                  height={"100px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content">
                                <span class="card-title">Nano Dental </span>

                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://dental.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/webdevelopment.png")}
                                  width={"175px"}
                                  height={"175px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content">
                                <span class="card-title">Nano Hospital </span>
                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://hospital.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/archive.png")}
                                  width={"100px"}
                                  height={"100px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content">
                                <span class="card-title">Nano Archive </span>
                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://archive.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/streaming.png")}
                                  width={"100px"}
                                  height={"100px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content">
                                <span class="card-title">Nano Stream</span>
                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://stream.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/virtual-assistant.png")}
                                  width={"75px"}
                                  height={"75px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content">
                                <span class="card-title">Nano Assistant </span>
                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://ai.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/restaurent.png")}
                                  width={"200px"}
                                  height={"200px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content">
                                <span class="card-title">Nano Restaurent </span>
                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://restaurent.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-4 col-lg-3"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              class="card"
                              style={{
                                padding: "20px",
                                boxShadow:
                                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                              }}
                            >
                              <div class="card-image">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/hotel.png")}
                                  width={"120px"}
                                  height={"120px"}
                                  alt="image"
                                />
                              </div>
                              <div class="card-content">
                                <span class="card-title">Nano Hotel </span>
                                <div
                                  class="card-action"
                                  style={{
                                    backgroundColor: "#F9AF5A",
                                    borderRadius: "10px",
                                    margin: "0px 2rem",
                                  }}
                                >
                                  <a
                                    href="https://hotel.nanovates.com"
                                    style={{ color: "#fff" }}
                                    target="_blank"
                                  >
                                    live preview
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row multi-columns-row ttm-boxes-spacing-5px">
                          <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                              {/* featured-thumbnail */}
                              <div className="featured-thumbnail">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/travel.png")}
                                  alt="image"
                                />
                              </div>
                              {/* featured-thumbnail end*/}
                              <div className="featured-content">
                                <div className="category">
                                  <p>Business</p>
                                </div>
                                <div className="featured-title">
                                  <h5>
                                    <a>Tour and Travel Management</a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            {/* featured-imagebox */}
                          </div>
                          <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                              {/* featured-thumbnail */}
                              <div className="featured-thumbnail">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/retail.png")}
                                  alt="image"
                                />
                              </div>
                              {/* featured-thumbnail end*/}
                              <div className="featured-content">
                                <div className="category">
                                  <p>Business</p>
                                </div>
                                <div className="featured-title">
                                  <h5>
                                    <a>Retail Business</a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            {/* featured-imagebox */}
                          </div>
                          <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                              {/* featured-thumbnail */}
                              <div className="featured-thumbnail">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/archive.png")}
                                  alt="image"
                                />
                              </div>
                              {/* featured-thumbnail end*/}
                              <div className="featured-content">
                                <div className="category">
                                  <p>Business</p>
                                </div>
                                <div className="featured-title">
                                  <h5>
                                    <a>Archive Management</a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            {/* featured-imagebox */}
                          </div>
                          <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                              {/* featured-thumbnail */}
                              <div className="featured-thumbnail">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/virtual-assistant.png")}
                                  alt="image"
                                />
                              </div>
                              {/* featured-thumbnail end*/}
                              <div className="featured-content">
                                <div className="category">
                                  <p>Business</p>
                                </div>
                                <div className="featured-title">
                                  <h5>
                                    <a>Virtual Assistant</a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            {/* featured-imagebox */}
                          </div>
                          <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                              {/* featured-thumbnail */}
                              <div className="featured-thumbnail">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/streaming.png")}
                                  alt="image"
                                />
                              </div>
                              {/* featured-thumbnail end*/}
                              <div className="featured-content">
                                <div className="category">
                                  <p>Business</p>
                                </div>
                                <div className="featured-title">
                                  <h5>
                                    <a>Real-time Data Streaming</a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            {/* featured-imagebox */}
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="row multi-columns-row ttm-boxes-spacing-5px">
                          <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                              {/* featured-thumbnail */}
                              <div className="featured-thumbnail">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/pharmacy.jpg")}
                                  alt="image"
                                />
                              </div>
                              {/* featured-thumbnail end*/}
                              <div className="featured-content">
                                <div className="category">
                                  <p>Health</p>
                                </div>
                                <div className="featured-title">
                                  <h5>
                                    <a>Pharmacy Management</a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            {/* featured-imagebox */}
                          </div>
                          <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                              {/* featured-thumbnail */}
                              <div className="featured-thumbnail">
                                <img
                                  className="img-fluid"
                                  src={require("../assets/bg/dental.avif")}
                                  alt="image"
                                />
                              </div>
                              {/* featured-thumbnail end*/}
                              <div className="featured-content">
                                <div className="category">
                                  <p>Health</p>
                                </div>
                                <div className="featured-title">
                                  <h5>
                                    <a>Dental Management</a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            {/* featured-imagebox */}
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Project_style1;
