import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../layout/Header";
import background1 from "../assets/bg/services1.jpg";

export class Faq extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div
          className="ttm-page-title-row"
          style={{ backgroundImage: `url(${background1})` }}
        >
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">FAQ's</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Home
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>faq</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <section className="ttm-row faqs-section clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-8 offset-lg-2">
                {/* section-title */}
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h5>Faq's</h5>
                    <h2 className="title">Do you have any questions?</h2>
                  </div>
                </div>
                {/* section-title end */}
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row">
              <div className="col-lg-6 col-md-6">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper">
                  <img
                    className="img-fluid"
                    src={require("../assets/bg/faq.png")}
                    title="single-img-eleven"
                    alt="single-img-eleven"
                  />
                </div>
                {/* ttm_single_image-wrapper end */}
              </div>
              <div className="col-lg-6 col-md-6 res-991-mt-30">
                {/* accordian */}
                <Accordion preExpanded={["foo"]}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What kind of services does TANAAD TECH provide?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        TANAAD TECH provides high advanced technology services,
                        including software development, artificial intelligence,
                        machine learning, cybersecurity, and data analytics.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What industries does TANAAD TECH serve?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        TANAAD TECH serves a variety of industries, including
                        healthcare, finance, manufacturing, energy, and
                        telecommunications.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is TANAAD TECH a global company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, TANAAD TECH operates globally and has clients from
                        all around the world.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does TANAAD TECH work with startups or only established
                        companies?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        TANAAD TECH works with both startups and established
                        companies, providing them with the technology services
                        they need to grow and succeed.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What sets TANAAD TECH apart from other technology
                        companies?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        TANAAD TECH is known for its advanced technology
                        solutions, top-notch expertise, and customer-centric
                        approach. We strive to understand our clients' unique
                        needs and provide tailored solutions that meet their
                        specific requirements.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How does TANAAD TECH ensure the security of its clients'
                        data?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        TANAAD TECH takes data security very seriously and
                        follows industry best practices to ensure the
                        confidentiality, integrity, and availability of its
                        clients' data. We employ advanced security measures,
                        including encryption, multi-factor authentication, and
                        regular security audits.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does TANAAD TECH offer training and support for its
                        technology solutions?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, TANAAD TECH provides training and support for all
                        of its technology solutions, ensuring that clients have
                        the knowledge and skills they need to use the solutions
                        effectively.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I get in touch with TANAAD TECH to discuss my
                        technology needs?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        You can fill out a contact form on our website, and one
                        of our representatives will be in touch with you
                        shortly. Alternatively, you can email us at
                        info@tanaadtech.com or call us at +252 613 845464.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            {/* row end*/}
            {/* row */}
            <div className="row mt-70 res-991-mt-30">
              <div className="col-lg-4 res-991-mb-20">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon style7 icon-align-top">
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-md">
                      <i className="flaticon flaticon-system" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Make It Simple</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>
                        With TANAAD TECH' AI and Machine Learning powered
                        systems.
                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-lg-4 res-991-mb-20">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon style7 icon-align-top">
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-md">
                      <i className="flaticon flaticon-data" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Unique Strategy</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>
                        our cutting-edge strategy that leverages nanotechnology
                        to revolutionize your industry and create a better
                        future.
                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-lg-4">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon style7 icon-align-top">
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-md">
                      <i className="flaticon flaticon-analytics" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Unique Planning</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>
                        Revolutionize the future with TANAAD TECH – our
                        cutting-edge technology will change the game in every
                        industry.
                      </p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
            </div>
            {/* row end*/}
          </div>
        </section>
      </div>
    );
  }
}

export default Faq;
