import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

class Mobilemenu extends Component {
  state = {
    collapse1: false,
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler
            id="hamburger1"
            onClick={() => this.toggleSingleCollapse("collapse1")}
          />
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            <MDBNavbarNav left>
              <MDBNavItem active>
                <MDBDropdown>
                  <a nav caret href="/">
                    <span>Home</span>
                  </a>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span>pages</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem
                      href={"/Aboutus_01"}
                    >
                      About Us
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={"/Services_02"}
                    >
                      Services
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={"/Our_expert"}
                    >
                      Our Expert
                    </MDBDropdownItem>
                    <MDBDropdownItem href={"/Faq"}>
                      FAQs
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={"/Contact_01"}
                    >
                      Contact Us
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <a nav caret href="/Services_02">
                    <span>services</span>
                  </a>
                                  </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <a nav caret href="/project_style1">
                    <span>Projects</span>
                  </a>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <a nav caret href="/Blog_grid">
                    <span>Blog</span>
                  </a>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  {/* <MDBDropdownToggle nav caret>
                    <span>Shop</span>
                  </MDBDropdownToggle> */}
                  {/* <MDBDropdownMenu>
                    <MDBDropdownItem href={"/Shop"}>
                      Default Shop
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={"/Product_details"}
                    >
                      Single Product Details
                    </MDBDropdownItem>
                    <MDBDropdownItem href={"/Cart"}>
                      Cart
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={"/Checkout"}
                    >
                      Checkout
                    </MDBDropdownItem>
                  </MDBDropdownMenu> */}
                </MDBDropdown>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobilemenu;
