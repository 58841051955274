import React, { Component } from "react";
import Header from "../layout/Header";
import background1 from "../assets/bg/services1.jpg";

export class Services_02 extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div
          className="ttm-page-title-row"
          style={{ backgroundImage: `url(${background1})` }}
        >
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Our Services</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Home
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Services</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <section className="ttm-row grid-section clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-8 offset-lg-2">
                {/* section-title */}
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h5 style={{ color: "#F9AF5A" }}>
                      Fast Growing Services
                    </h5>
                    <h2 className="title">
                      Providing High-Performance Services For Multiple{" "}
                      <span style={{ color: "#F9AF5A" }}>
                        Information &amp; Technologies!
                      </span>
                    </h2>
                  </div>
                </div>
                {/* section-title end */}
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row">
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/itconsultancy.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a>IT Consultancy</a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Unlock your business's full potential with our expert IT
                        consulting services. Our experienced consultants can
                        help you streamline your processes, optimize your
                        technology stack, and boost your bottom line.
                      </p>
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/datascience.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a>Data Analysis</a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Gain a competitive edge with expert data analysis. Our
                        skilled team of analysts can help you identify
                        opportunities, reduce risks, and optimize your business
                        for success.
                      </p>
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/machinelearning.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a>Machine Learning</a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Streamline your workflows and eliminate inefficiencies
                        with our powerful machine learning models, built to
                        automate repetitive tasks and free up your team's time.
                        Unlock the full potential of your data with our powerful
                        machine learning models, crafted by expert data
                        scientists and engineers.
                      </p>
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/ai.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a >
                          Artificial Intelligence
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Experience the power of AI with our comprehensive suite
                        of services. From natural language processing to
                        predictive analytics, we offer a range of solutions that
                        transform data into actionable insights.
                      </p>
                    </div>
                    <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2"
                    >
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/blockchain.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a >
                          Blockchain Development
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Secure your digital assets with our reliable and
                        customized blockchain solutions. Partner with us for
                        reliable and secure blockchain development solutions
                        that drive growth and innovation.
                      </p>
                    </div>
                    <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2"
                    >
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail box-shadow">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/hosting.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a>Web Hosting</a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Want to take your website to the next level? Our web
                        hosting services offer unlimited bandwidth, storage, and
                        email accounts to give you the flexibility and
                        scalability you need.
                      </p>
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/domain.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a>Domain Registration</a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Secure your online presence with our reliable and
                        affordable domain registration services.
                      </p>
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/uiux.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a>UI/UX Design</a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Our UI/UX design services will help your business stand
                        out from the crowd and increase user engagement. Attract
                        and retain customers with our stunning UI/UX design that
                        emphasizes functionality and aesthetics.{" "}
                      </p>
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/graphic.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a>Graphic Design</a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Capture your audience's attention with eye-catching
                        visuals that communicate your message." "Transform your
                        vision into reality with our bespoke graphic design
                        services." "Stand out from the competition with unique
                        and creative designs tailored to your brand."{" "}
                      </p>
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
              <div className="col-md-6 col-lg-4">
                {/* featured-imagebox-services */}
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                  <div className="featured-thumbnail">
                    {/* featured-thumbnail */}
                    <img
                      className="img-fluid"
                      src={require("../assets/bg/graphic.png")}
                      alt="image"
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a>Internship Programs</a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                        Ready to take your education to the next level? Our
                        internship program provides a dynamic learning
                        environment where you can apply classroom knowledge to
                        real-world challenges and build a strong foundation for
                        your future.
                      </p>
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
                {/* featured-imagebox-services */}
              </div>
            </div>
            {/* row end*/}
          </div>
        </section>
      </div>
    );
  }
}

export default Services_02;
