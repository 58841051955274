import React, { Component } from "react";
import Header from "../layout/Header";
import background1 from "../assets/bg/services1.jpg";

export class Our_expert extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div
          className="ttm-page-title-row"
          style={{ backgroundImage: `url(${background1})` }}
        >
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">Our Experts</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Home
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Our Experts</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
      </div>
    );
  }
}

export default Our_expert;
