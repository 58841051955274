import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route } from "react-router-dom";

export class Menu extends Component {
  render() {
    return (
      <nav id="menu" role="navigation" className="menu">
        <ul>
          <li className="menu-hasdropdown">
            <a href="/">Home</a>
            {/* <ul className="menu-dropdown">
                            <li><a href={'/'}>Homepage 1</a></li>
                            <li><a href={'/Home2'}>Homepage 2</a></li>
                            <li><a href={'/Home3'}>Homepage 3</a></li>
                            <li className="menu-hasdropdown menu-hasflyout">
                                <a>header styles</a>
                                <ul className="menu-dropdown">
                                <li><a href={'/'}>Header Style 01</a></li>
                                <li><a target="_blank" href={'/Header_style_02'}>Header Style 02</a></li>
                                <li><a target="_blank" href={'/Header_style_03'}>Header Style 03</a></li>
                                </ul>
                            </li>
                        </ul> */}
          </li>
          <li className="menu-hasdropdown">
            <a>pages</a>
            <ul className="menu-dropdown">
              <li>
                <a href={"/aboutus_01"}>About Us</a>
              </li>
              {/* <li><a href={'/aboutus_02'}>About Us 2</a></li> */}
              {/* <li><a href={'/Services_01'}>Services 1</a></li> */}
              <li>
                <a href={"/Services_02"}>Services</a>
              </li>
              <li>
                <a href={"/Our_expert"}>Our Experts</a>
              </li>
              <li>
                <a href={"/Faq"}>FAQs</a>
              </li>
              <li>
                <a href={"/Contact_01"}>Contact Us</a>
              </li>
              {/* <li><a href={'/Contact_02'}>Contact Us 2</a></li>                                                     */}
              {/* <li><a href={'/Error'}>Error Page</a></li> */}
              {/* <li><a href={'/Element'}>Elements</a></li> */}
            </ul>
          </li>
          <li className="menu-hasdropdown">
            <a href="/Services_02">services</a>
          </li>
          <li className="menu-hasdropdown">
            <a href={"/project_style1"}>Projects</a>
            {/* <ul className="menu-dropdown">
                            <li><a href={'/project_style1'}>Project Style 1</a></li>
                            <li><a href={'/project_style2'}>Project Style 2</a></li>
                            <li className="menu-hasdropdown menu-hasflyout">
                                <a href={'/single_style1'}>Project Single</a>
                                <ul className="menu-dropdown">
                                    <li><a href={'/single_style1'}>Single Style 1</a></li>
                                    <li><a href={'/single_style2'}>Single Style 2</a></li>
                                    <li><a href={'/single_style3'}>Single Style 3</a></li>
                                </ul>
                            </li>
                        </ul> */}
          </li>
          <li className="menu-hasdropdown">
            <a href={"/blog_grid"}>Blog</a>
            {/* <ul className="menu-dropdown">
                            <li><a href={'/blog'}>Blog Classic</a></li>
                            <li><a href={'/blog_grid'}>Blog Grid View</a></li>
                            <li><a href={'/blog_top_image'}>Blog Top Image</a></li>
                            <li><a href={'/blog_left_image'}>Blog Left Image</a></li>
                            <li><a href={'/single_blog'}>Blog Single View</a></li>
                        </ul> */}
          </li>
          {/* <li className="menu-hasdropdown"><a>Shop</a>
                        <ul className="menu-dropdown">
                            <li><a href={'/Shop'}>Default Shop</a></li>
                            <li><a href={'/Product_details'}>Single Product Details</a></li>
                            <li><a href={'/Cart'}>Cart</a></li>
                            <li><a href={'/Checkout'}>Checkout</a></li>
                        </ul>
                    </li> */}
          <li>
            <a href={"/Contact_01"}>Contact Us</a>
          </li>
        </ul>
      </nav>
    );
  }
}
