import React, { Component } from "react";
import { MDBProgress } from "mdbreact";
import Slider from "react-slick";
import CountUp from "react-countup";
import Header from "../layout/Header";
import background1 from "../assets/bg/aboutus1.jpg";
import background2 from "../assets/bg/aboutus2.jpg";

export class Aboutus_01 extends Component {
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row" style={{backgroundImage: `url(${background1})` }}>
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title" >About Us</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Home
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span style={{color: '#F9AF5A'}}>About Us</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5 style={{color: '#F9AF5A'}}>About us</h5>
                    <h2 className="title">
                      We are here to IT Solution with over 10 years of{" "}
                      <span style={{color: '#F9AF5A'}}>experience</span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      Web designing in a powerful way of just not an only
                      professions, however, in a passion for our Company. We
                      have to a tendency to believe the idea that smart looking
                      of any website is the first impression on visitors. And
                      the smart design of any website is the starting point.
                    </p>
                    <p>
                      We have a special team for website style who has been
                      involved in designing professional websites of all
                      categories.
                    </p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row no-gutters mt-4 mb-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          24/7 Hours Support Flexible Price{" "}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Web And Mobile Application{" "}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          New Domain Registration
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Data Text Synchronization
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Quick To Respond Flexible Price{" "}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Improving Our Website Design
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Web &amp; Email Hosting Services{" "}
                        </span>
                      </li>
                      <li>
                        <i className="ti-check-box ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Artificial Intelligence Web App
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* row END*/}
                <p className="mb-2">
                  High-tech industries play an important role in the modern
                  economy, and often experience significantly higher pay than
                  other industries. And Digital solutions have transformed the
                  world’s for web applications.
                </p>
                <a className="ttm-btn ttm-btn-size-md mt-4" style={{backgroundColor: '#F9AF5A'}}>
                  More About Us
                </a>
              </div>
              <div className="col-lg-6">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper text-sm-center">
                    <img
                      className="img-fluid"
                      src={require('../assets/bg/itsolutions.png')}
                      title="single-img-seven"
                      alt="single-img-seven"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* aboutus-section end*/}
        {/* progress-bar-section */}
        <section className="ttm-row zero-padding-section res-991-p-0 ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-md-12">
                <div className="ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-left-span spacing-7 position-relative z-1">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                  <div className="layer-content">
                    <div className="row">
                      <div className="col-lg-6">
                        {/* ttm_single_image-wrapper */}
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={require('../assets/bg/itprofessional.png')}
                            title="single-img-eight"
                            alt="single-img-eight"
                          />
                        </div>
                        {/* ttm_single_image-wrapper end */}
                        <div className="about-overlay-02" style={{backgroundColor: '#F9AF5A'}}>
                          <h3>+10 Years of</h3>
                          <p>IT Business Experience</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="res-991-mt-30">
                          {/* section title */}
                          <div className="section-title with-desc clearfix">
                            <div className="title-header">
                              <h5 style={{color: '#F9AF5A'}}>About TANAAD TECH</h5>
                              <h2 className="title">
                                We deal with the aspects of professional{" "}
                                <span style={{color: '#F9AF5A'}}>IT Services</span>
                              </h2>
                            </div>
                            <div className="title-desc">
                              <p>
                                We listen. We advise. We design, together. Happy
                                customers and ongoing relationships are what we
                                strive for. Success is measured by results, the
                                most important being how our clients feel about
                                their experience with us.
                              </p>
                            </div>
                          </div>
                          {/* section title end */}
                          <div className="mt-4">
                            {/* progress-bar */}
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Web and Mobile Engineering
                              </div>
                              {/* progress-bar-title */}
                              <label className="float-right">99%</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={99}
                              ></MDBProgress>
                            </div>
                            {/* progress-bar END */}
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                UI/UX Design
                              </div>
                              {/* progress-bar-title */}
                              <label className="float-right">90%</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={90}
                              ></MDBProgress>
                            </div>
                            {/* progress-bar */}
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Cloud Engineering
                              </div>
                              {/* progress-bar-title */}
                              <label className="float-right">80%</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={80}
                              ></MDBProgress>
                            </div>
                            {/* progress-bar END */}
                            {/* progress-bar */}
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Data Science Engineering
                              </div>
                              {/* progress-bar-title */}
                              <label className="float-right">80%</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={80}
                              ></MDBProgress>
                            </div>
                            <div className="ttm-progress-bar">
                              <div className="progress-bar-title float-left">
                                Automations
                              </div>
                              {/* progress-bar-title */}
                              <label className="float-right">80%</label>
                              <MDBProgress
                                className="w-100"
                                material
                                value={80}
                              ></MDBProgress>
                            </div>
                            {/* progress-bar END */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* progress-bar-section end */}
        {/* fid-section */}
        <section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes clearfix"
        style={{backgroundImage: `url(${background2})`, marginBottom:'4%'}}>
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-md-2 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-online-library" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={458} duration={15} />
                    </h4>
                    <h3 className="ttm-fid-title">Completed Projects</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-2 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-developer" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={954} duration={15} />
                    </h4>
                    <h3 className="ttm-fid-title">Happy Clients</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-4 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-24h" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={897} duration={15} />
                    </h4>
                    <h3 className="ttm-fid-title">Services Provided</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-2 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-report" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={785} duration={15} />
                    </h4>
                    <h3 className="ttm-fid-title">Cloud Designs</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
              <div className="col-md-2 col-6">
                {/*  ttm-icon */}
                <div className="ttm-fid inside ttm-fid-view-topicon">
                  <div className="ttm-fid-icon-wrapper">
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-lg">
                      <i className="flaticon flaticon-report" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="ttm-fid-contents">
                    {/*  ttm-fid-contents */}
                    <h4>
                      <CountUp start={0} end={350} duration={15} />
                    </h4>
                    <h3 className="ttm-fid-title">Data Engineering</h3>
                    {/*  ttm-fid-title */}
                  </div>
                  {/* ttm-fid-contents end */}
                </div>
              </div>
            </div>
            {/* row end*/}
          </div>
        </section>
        {/* fid-section end */}
       
        {/* our-partner-section */}
        <section className="ttm-row our-client-section res-991-p-0 ttm-bgcolor-skincolor clearfix"
        style={{marginBottom: '4%', backgroundColor: '#262362'}}>
          <div className="container-xl">
            <div className="row">
              <div className="col-12 text-center">
                <Slider
                  className="row slick_slider ttm-boxes-spacing-30px"
                  {...slick_slider}
                  slidesToShow={5}
                >
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div
                          className="ttm-client-logo-tooltip"
                          data-tooltip="client-01"
                        >
                          <img
                            className="img-fluid"
                            src={require('../assets/bg/client1.png')}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div
                          className="ttm-client-logo-tooltip"
                          data-tooltip="client-02"
                        >
                          <img
                            className="img-fluid"
                            src={require('../assets/bg/client2.png')}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div
                          className="ttm-client-logo-tooltip"
                          data-tooltip="client-03"
                        >
                          <img
                            className="img-fluid"
                            src={require('../assets/bg/client3.png')}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div
                          className="ttm-client-logo-tooltip"
                          data-tooltip="client-04"
                        >
                          <img
                            className="img-fluid"
                            src={require('../assets/bg/client4.png')}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="client-box ttm-box-view-boxed-logo">
                      <div className="client">
                        <div
                          className="ttm-client-logo-tooltip"
                          data-tooltip="client-05"
                        >
                          <img
                            className="img-fluid"
                            src={require('../assets/bg/client1.png')}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* our-partner-section end */}
      </div>
    );
  }
}

export default Aboutus_01;
