import React, { Component } from "react";
import {
  MDBAnimation,
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBMask,
  MDBContainer,
  MDBRow,
} from "mdbreact";
import crousel2 from "../assets/bg/crousel2.jpg";
import crousel1 from "../assets/bg/crousel1.jpg";

export class CarouselPage extends Component {
  render() {
    return (
      <MDBContainer fluid>
        <MDBRow>
          <MDBCarousel
            activeItem={1}
            length={2}
            showControls={true}
            showIndicators={false}
            id="Home_banner"
          >
            <MDBCarouselInner>
              <MDBCarouselItem itemId="1">
                <MDBView>
                  <img
                    className="d-block w-100"
                    src={crousel1}
                    alt="First slide"
                  />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner1">
                      <MDBAnimation className="slideInUp" count={1} delay="2s">
                        <h2 className="ttm-textcolor-darkgrey flipInY">
                          Best{" "}
                          <strong style={{color:'#F9AF5A'}}>
                            Platform
                          </strong>{" "}
                          to Grow Your Bussiness
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation
                        className="slideInUp"
                        count={1}
                        delay="2.5s"
                      >
                        <p className="text">
                          We are equipped with an updated technical knowledge to
                          serve our customers
                          <br /> properly. Our method of application maintains
                          the industry.
                        </p>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="3s">
                        <p>
                          <a href="/Contact_01" className="ttm-btn ttm-btn-size-md ttm-textcolor-white" style={{backgroundColor:'#F9AF5A'}}>
                            Discuss Your Project{" "}
                          </a>
                          &nbsp; &nbsp;
                          <a href="/Contact_01" className="ttm-btn btn-inline ttm-btn-size-md ttm-textcolor-darkgrey ml-3">
                            <i className="ti-arrow-circle-right" />
                            get a Quote
                          </a>
                        </p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
              <MDBCarouselItem itemId="2">
                <MDBView>
                  <img
                    className="d-block w-100"
                    src={crousel2}
                    alt="Second slide"
                  />
                </MDBView>
                <MDBCarouselCaption>
                  <div className="container-xl">
                    <div className="carousel-caption-inner banner2">
                      <MDBAnimation className="slideInUp" count={1} delay="5s">
                        <h2 className="ttm-textcolor-darkgrey">
                          Real-Time Monitoring Your Business{" "}
                          <strong style={{color:'#F9AF5A'}}>
                          Infrastructure
                          </strong>
                        </h2>
                      </MDBAnimation>
                      <MDBAnimation
                        className="slideInUp"
                        count={1}
                        delay="5.5s"
                      >
                        <p className="text">
                          We are equipped with an updated technical knowledge to
                          serve our customers
                          <br /> properly. Our method of application maintains
                          the industry.
                        </p>
                      </MDBAnimation>
                      <MDBAnimation className="slideInUp" count={1} delay="3s">
                        <p>
                          <a href="/Aboutus_01" className="ttm-btn ttm-btn-size-md ttm-textcolor-white mr-3" style={{backgroundColor:'#F9AF5A'}}>
                            read more
                          </a>
                          <a href="/project_style1" className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white">
                            get nanosystem
                          </a>
                        </p>
                      </MDBAnimation>
                    </div>
                  </div>
                </MDBCarouselCaption>
              </MDBCarouselItem>
            </MDBCarouselInner>
          </MDBCarousel>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default CarouselPage;
