import React, { Component } from "react";
import Slider from "react-slick";
import { MDBProgress } from "mdbreact";
import { Header } from "./layout/Header";
import { CarouselPage } from "./banner/Home1_banner";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const images = [
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
];

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="site-main">
        <Header />
        {/* home banner */}
        <CarouselPage />
        {/* home banner end*/}
        {/* service-section */}
        <section className="ttm-row service-section position-relative z-1 clearfix">
          <div className="container-xl">
            <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
              <div className="col-md-4">
                <div
                  className="featured-icon-box left-icon style1 ttm-textcolor-white h-100"
                  style={{ backgroundColor: "#262362" }}
                >
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                      <i className="flaticon flaticon-system" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Trusted Services</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>Trust us for reliable and dependable services</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {/*  featured-icon-box */}
                <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                      <i className="flaticon flaticon-clock" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>24/7 Support</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>15APR2023</p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
              <div className="col-md-4">
                {/*  featured-icon-box */}
                <div
                  className="featured-icon-box left-icon style1 ttm-textcolor-white h-100"
                  style={{ backgroundColor: "#262362" }}
                >
                  <div className="featured-icon">
                    {/*  featured-icon */}
                    <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                      <i className="flaticon flaticon-developer" />
                      {/*  ttm-icon */}
                    </div>
                  </div>
                  <div className="featured-content">
                    {/*  featured-content */}
                    <div className="featured-title">
                      {/*  featured-title */}
                      <h5>Expert &amp; Professional</h5>
                    </div>
                    <div className="featured-desc">
                      {/*  featured-desc */}
                      <p>We Are Trusted Our Customers</p>
                    </div>
                  </div>
                </div>
                {/*  featured-icon-box END */}
              </div>
            </div>
          </div>
        </section>
        {/* service-section end */}
        {/* aboutus-section */}
        <section className="ttm-row aboutus-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                {/* section title */}
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h5 style={{ color: "#F9AF5A" }}>About us</h5>
                    <h2 className="title">
                      We are here to IT Solution with over 10 years of{" "}
                      <span style={{ color: "#F9AF5A" }}>
                        experience
                      </span>
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      Web designing in a powerful way of just not an only
                      professions, however, in a passion for our Company. We
                      have to a tendency to believe the idea that smart looking
                      of any website is the first impression on visitors. And
                      the smart design of any website is the starting point.
                    </p>
                    <p>
                      We have a special team for website style who has been
                      involved in designing professional websites of all
                      categories.
                    </p>
                  </div>
                </div>
                {/* section title end */}
                {/* row */}
                <div className="row no-gutters mt-4">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Web And Mobile Application{" "}
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          New Domain Registration
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Data Text Synchronization
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Data Science Engineering
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Improving Our Website Design
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Web &amp; Email Hosting Services{" "}
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Artificial Intelligence Web App
                        </span>
                      </li>
                      <li>
                        <i className="far fa-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Machine Learning &amp; IoT
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* row END*/}
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-4 mb-4" />
                </div>
                {/* separator */}
                <div className="fs-16 ttm-textcolor-darkgrey">
                  <span>
                    Call to ask{" "}
                    <a className="fw-500 ttm-textcolor-darkgrey">
                      <u>any question</u>
                    </a>
                  </span>
                  <strong className="fs-18">: 15APR2023</strong> or{" "}
                  <strong className="fs-18">17APR2023</strong>
                </div>
              </div>
              <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                <div className="position-relative res-991-mt-30">
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper">
                    <img
                      className="img-fluid"
                      src={require("./assets/bg/itsolutions.png")}
                      title="single-img-one"
                      alt="single-img-one"
                    />
                  </div>
                  {/* ttm_single_image-wrapper end */}
                  {/*featured-icon-box*/}
                  {/* <div className="about-overlay-01">
                    <p className="mb-0">
                      American IT Solutions Association AIA.
                    </p>
                  </div> */}
                  {/* featured-icon-box end*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* aboutus-section end */}
        {/* history-section */}
        <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                {/* section title */}
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h5 style={{ color: "#F9AF5A" }}>
                      Our Services
                    </h5>
                    <h2 className="title">
                      We run all kinds of services in form of{" "}
                      <span style={{ color: "#F9AF5A" }}>
                        Information &amp; Technologies
                      </span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            <Slider
              className="row slick_slider ttm-boxes-spacing-30px"
              {...slick_slider}
            >
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-business-and-finance" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>IT Consultancy</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      Expertise in IT consultancy for the IT companies, for
                      their different working areas Web-app related.
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-computer" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>IT Management</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      We provide all kind of IT management services of in the
                      according to on their organization prioritie.
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-data" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>Product Design</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      Our firm is expert to create an efficient for user
                      interface that makes design user interaction lively.
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-global-1" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>Cloud Services</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      We develop, migrate and work on web applications to ensure
                      that they run on capably on clouds.
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-server" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>Web Hosting</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      Experience lightning-fast web hosting solutions for all
                      your systems with our reliable and dedicated servers.
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  {/* ttm-history-box-wrapper  */}
                  <div className="ttm-history-box-icon-wrapper">
                    {/* ttm-history-box-icon-wrapper  */}
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-server" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  {/* ttm-history-box-border  */}
                  <div className="ttm-history-box-details ttm-bgcolor-white">
                    <div className="ttm-historybox-title">
                      <h5>Domain Registration</h5>
                    </div>
                    {/* historybox-title  */}
                    <div className="ttm-historybox-description">
                      {/* description  */}
                      Secure your online identity with our comprehensive domain
                      registration services. From .com to country-specific
                      domains, we've got you covered.
                    </div>
                    <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">
                      Read More <i className="ti ti-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>
            </Slider>

            <div className="row">
              <div className="col-md-12 text-center mt-5 res-991-mt-30">
                <p className="mb-0">
                  Don’t hesitate, contact us for better help and services.{" "}
                  <strong>
                    <u>
                      <a className="ttm-textcolor-darkgrey">
                        {" "}
                        View more Services
                      </a>
                    </u>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* history-section end*/}

        {/* team-section end*/}
        {/* first-row-title-section */}
        <section
          className="ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes clearfix"
          style={{ backgroundColor: "#262362" }}
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              {/* row */}
              <div className="col-lg-8 offset-lg-2">
                <div className="text-center">
                  <div className="ttm-play-icon-btn mb-5">
                    <div className="ttm-play-icon-animation">
                      <a>
                        <div className="ttm-icon ttm-bgcolor-white ttm-textcolor-skincolor ttm-icon_element-size-sm rounded-circle">
                          <i className="fas fa-play" />
                        </div>
                      </a>
                    </div>
                  </div>
                  {/* section-title */}
                  <div className="section-title with-desc text-center clearfix">
                    <div className="title-header">
                      <h2 className="title">
                        We Provide Outsourced IT Services For Small &amp;{" "}
                        <span className="ttm-textcolor-white">
                          Mid-Sized Business
                        </span>
                      </h2>
                    </div>
                    <div className="title-desc">
                      Appropriate for your specific business, making it easy for
                      <br /> you to have quality IT services. What We Do
                    </div>
                  </div>
                  {/* section-title end */}
                  <div className="mt-5">
                    <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"
                    href="/Services_02">
                      More Services
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* first-row-title-section end*/}
        {/* testimonial-section */}
        <section className="ttm-row zero-padding-section clearfix">
          <div className="container-xl">
            <div className="row no-gutters mt_100 res-991-mt-0">
              <div className="col-md-12">
                <div className="ttm-bgcolor-darkgrey ttm-textcolor-white z-11 position-relative h-100">
                  <div className="layer-content">
                    {/* layer-content */}
                    <div className="spacing-2">
                      {/* spacing-2 */}
                      {/* section title */}
                      <div className="section-title ttm with-desc clearfix">
                        <div className="title-header">
                          <h5>What We Do</h5>
                          <h2 className="title">
                            We help you to grow your business{" "}
                            <span className="ttm-textcolor-white">
                              Exponentially
                            </span>
                          </h2>
                        </div>
                      </div>
                      {/* section title end */}
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          Web & Mobile Development
                        </div>
                        {/* progress-bar-title */}
                        <label className="float-right">99%</label>
                        <MDBProgress
                          className="w-100"
                          material
                          value={99}
                        ></MDBProgress>
                      </div>
                      {/* progress-bar */}
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          UI/UX Design
                        </div>
                        {/* progress-bar-title */}
                        <label className="float-right">95%</label>
                        <MDBProgress
                          className="w-100"
                          material
                          value={95}
                        ></MDBProgress>
                      </div>
                      {/* progress-bar END */}
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          Data Science
                        </div>
                        {/* progress-bar-title */}
                        <label className="float-right">95%</label>
                        <MDBProgress
                          className="w-100"
                          material
                          value={95}
                        ></MDBProgress>
                      </div>
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          Blockchain Development
                        </div>
                        {/* progress-bar-title */}
                        <label className="float-right">85%</label>
                        <MDBProgress
                          className="w-100"
                          material
                          value={85}
                        ></MDBProgress>
                      </div>
                      <div className="ttm-progress-bar">
                        <div className="progress-bar-title float-left">
                          Consultation
                        </div>
                        {/* progress-bar-title */}
                        <label className="float-right">80%</label>
                        <MDBProgress
                          className="w-100"
                          material
                          value={80}
                        ></MDBProgress>
                      </div>
                      {/* progress-bar END */}
                    </div>
                    {/* spacing-2 END */}
                  </div>
                  {/* layer-content END */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* testimonial-section end */}
        {/* process-section */}
        <section className="ttm-row process-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                {/* section title */}
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h5 style={{ color: "#F9AF5A" }}>how it work</h5>
                    <h2 className="title">
                      Check Out Our Work{" "}
                      <span style={{ color: "#F9AF5A" }}>
                        Process
                      </span>
                    </h2>
                  </div>
                </div>
                {/* section title end */}
              </div>
            </div>
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-processbox-wrapper">
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-one"
                        title="step-one"
                        src={require("./assets/bg/stpe-1.jpg")}
                      />
                      <div className="process-num">
                        <span className="number">01</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Choose a Service</h5>
                      </div>
                      <div className="ttm-box-description">
                        Choose our top-notch services and experience
                        unparalleled quality and customer satisfaction.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-two"
                        title="step-two"
                        src={require("./assets/bg/step-two.jpg")}
                      />
                      <div className="process-num">
                        <span className="number">02</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Request a Meeting</h5>
                      </div>
                      <div className="ttm-box-description">
                        o request our service, simply fill out our online form
                        with your details and service requirements.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-three"
                        title="step-three"
                        src={require("./assets/bg/step-3.jpg")}
                      />
                      <div className="process-num">
                        <span className="number">03</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Receive Custom Plan</h5>
                      </div>
                      <div className="ttm-box-description">
                        Say goodbye to generic solutions and hello to a
                        tailor-made plan just for you. Receive a custom plan
                        designed to fit your unique needs and achieve your
                        goals.
                      </div>
                    </div>
                  </div>
                  <div className="ttm-processbox text-center res-991-mb-0">
                    <div className="ttm-box-image">
                      <img
                        className="img-fluid"
                        alt="step-four"
                        title="step-four"
                        src={require("./assets/bg/step-4.jpg")}
                      />
                      <div className="process-num">
                        <span className="number">04</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Let’s Make it Happen</h5>
                      </div>
                      <div className="ttm-box-description">
                        Great, let's make it happen! We are excited to get
                        started and work with you to turn this plan into a
                        successful reality.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </section>
        {/* portfolio-section */}
        <section
          className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes ttm-bgcolor-skincolor clearfix"
          style={{ backgroundColor: "#262362", marginBottom: "4%" }}
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl pb-5 res-991-pb-0">
            <div className="row align-items-center ttm-textcolor-white">
              <div className="col-lg-5 res-991-text-center">
                <div className="section-title style3 mb-0 clearfix">
                  <div className="title-header mb-0">
                    <h5>We Make Connections</h5>
                    <h2 className="title">
                      Explore recent{" "}
                      <span className="ttm-textcolor-white">Projects</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 res-991-text-center">
                <div className="title-desc">
                  Our IT solutions are tailor-made to meet your unique business
                  needs. From ideation to execution, we deliver innovative and
                  reliable technology solutions that drive your business
                  forward.
                </div>
              </div>
              <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
                <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"
                href="/Aboutus_01">
                  {" "}
                  Read More{" "}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
